export default [
  {
    name: 'list',
    method: 'GET',
    desc: '产品列表',
    path: '/apis/method/soa.ncov.api.product_list',
  },
  {
    name: 'detail',
    method: 'GET',
    desc: '产品详情',
    path: '/apis/method/soa.ncov.api.product_detail',
  },
  {
    name: 'pay',
    method: 'POST',
    desc: '提交支付',
    path: '/apis/method/soa.external_payment.doctype.external_payment_link.api.viapay',
  },
]
