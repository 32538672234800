import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/utils/vant'
import '@/utils/ant'
import utils from '@/utils'

import VueGtag from 'vue-gtag'

Vue.use(
  VueGtag,
  {
    config: { id: 'G-DQJGVJT5HB' },
    pageTrackerTemplate(to) {
      return {
        page_title: 'shop' + to.meta.title || '',
        page_path: location.pathname + location.hash,
      }
    },
  },
  router
)

Vue.use(utils)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
