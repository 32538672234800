/** @format */

export default {
  state: {
    ua: 'phone',
  },
  mutations: {
    // 设置用户信息
    SET_UA: (state, ua) => {
      state.ua = ua
    },
  },
  actions: {},
}
