<template>
  <div class="header-title">
    <div v-if="ua === 'phone'">
      <van-nav-bar
        v-if="showLeft"
        fixed
        :title="title"
        left-text="返回"
        :left-arrow="showLeft"
        @click-left="onClickLeft"
      >
        <template #right>
          <van-icon @click="handleLink" name="apps-o" size="18" color="#ff6034" />
        </template>
      </van-nav-bar>
      <van-nav-bar v-else fixed :title="title">
        <template #left>
          <img style="width: 30px" src="@/assets/logo.svg" alt="" @click="goToHome" />
        </template>
        <template #right>
          <van-icon @click="handleLink" name="apps-o" size="18" color="#ff6034" />
        </template>
      </van-nav-bar>
      <van-share-sheet v-model="visible" title="链接列表" :options="options" @select="onSelect" />
    </div>

    <div class="header-wrap" v-else ref="header">
      <div class="header-con">
        <div class="left-wrap" v-if="showLeft">
          <a-icon @click="onClickLeft" type="arrow-left" />
          <h2>{{ title }}</h2>
        </div>
        <div class="left-wrap" style="cursor: pointer" @click="goToHome" v-else>
          <img src="@/assets/logo.svg" alt="" />
          <h2>{{ title }}</h2>
        </div>
        <div>
          <a-button type="warnging" icon="contact" @click="contact">联系客服</a-button>
          <a-popover
            :getPopupContainer="() => $refs.header"
            class="my-popover"
            title="链接列表"
            trigger="hover"
            placement="bottomRight"
          >
            <template slot="content">
              <div class="popover-item" v-for="(op, index) in options" :key="index" @click="onSelect(op)">
                <img :src="op.icon" alt="" />
                <p>{{ op.name }}</p>
              </div>
            </template>
            <a-button type="primary" icon="menu" key="3"> 链接列表 </a-button>
          </a-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HelloWorld',
  props: {
    title: {
      default: '厚仁教育',
      type: String
    },
    showLeft: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      visible: false,
      options: [
        {
          name: '官网',
          icon: require('@/assets/wh.png'),
          link: 'https://www.wholeren.com'
        },
        {
          name: '疫情网',
          icon: require('@/assets/wh.png'),
          link: 'https://c.wholeren.com/'
        },
        { name: '美国校园资讯', icon: require('@/assets/1-mini.jpg') },
        { name: '厚仁服务项目', icon: require('@/assets/2-mini.jpg') },
        {
          name: '联系我们',
          icon: require('@/assets/contact.jpg'),
          link: 'https://direct.lc.chat/9055235/1?name=pay'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['ua'])
  },
  created() {},
  mounted() {},
  watch: {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.matched.length) {
        vm.backName = to.name;
        vm[to.name] = true;
      }

      console.log('fromvm stae', to, from, vm);
    });
  },

  methods: {
    goToHome() {
      window.open('https://www.wholeren.com');
    },
    handleLink() {
      this.visible = true;
    },
    onSelect(item) {
      if (item.link) {
        this.openBrowser(item.link);
      } else {
        this.$toast('请使用微信识别二维码');
      }
    },
    openBrowser(url) {
      var u = navigator.appVersion;
      console.log(u, 'uuu');
      var isIosUc = u.includes('iPhone') && u.includes('UCBrowser');
      var isWeixin = u.includes('MicroMessenger');
      if (isIosUc || isWeixin) {
        window.location.href = url;
      } else {
        this.newTab = window.open();
        setTimeout(() => {
          this.newTab.location.href = url;
        });
      }
    },
    contact() {
      window.open(`https://direct.lc.chat/9055235/1?name=pay`);
    },
    onClickLeft() {
      // this.$router.back();
      console.log(this.$router);
      if (this[this.backName]) {
        this.$router.back();
      } else {
        this.$router.push('/');
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header-title {
  /deep/ .van-nav-bar__left {
    i {
      color: #ff6034;
    }
    span {
      color: #ff6034;
    }
  }
  .header-wrap {
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    .header-con {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 1000px;
      height: 60px;
      margin: 0 auto;
      .left-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {
          cursor: pointer;
          margin-right: 10px;
          color: rgba(0, 0, 0, 0.85);
        }
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        h2 {
          margin-bottom: 0;
        }
      }
      .my-popover {
        margin-left: 20px;
      }
    }
    .popover-item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      img {
        width: 100px;
        height: 100px;
      }
      p {
        margin: 0;
        margin-left: 10px;
        font-size: 18px;
      }
    }
  }
}
</style>
