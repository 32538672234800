<template>
  <div class="home" :class="[ua]">
    <HeaderTitle title="厚仁教育"></HeaderTitle>
    <div class="con-wrap">
      <div class="title-wrap">
        <h2>美国厚仁教育</h2>
        <h3 v-if="ua === 'web'">公司介绍</h3>
        <p>
          美国厚仁教育集团 WholeRen Group, LLC
          成立于2010年，旨在提供基于美国的全面教育与发展，为学生制定全方位的留学服务——包括美国初 /
          高中留学申请和转学、美国本科 /
          研究生申请和转学、前50美国名校保录、紧急学术应对、学术辅导、美国寄宿家庭服务、微留学、360°后续成长支持、背景提升和职业规划、企业高管研修等。
        </p>
        <p>
          厚仁教育是全美首家获得美国国际招生协会AIRC权威认证的留学服务机构，曾接受CCTV、纽约时报、华尔街日报等主流媒体特约专访，是最了解美中教育差异的专家，是指导留美学生成功的导师。
        </p>
      </div>

      <div class="list-wrap">
        <div class="list-title">服务项目</div>
        <a-skeleton class="skeleton" v-if="loading" active />
        <div class="list" v-else>
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <CardItem :item="item"></CardItem>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import CardItem from './item/CardItem';
import HeaderTitle from '@/components/HeaderTitle';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    CardItem,
    HeaderTitle
  },
  props: {},
  data() {
    return {
      list: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters(['ua'])
  },
  created() {},
  mounted() {
    this.getData();
  },
  watch: {},
  methods: {
    async getData() {
      console.log(this, 'this');
      this.loading = true;
      let res = await this.$api['home/list']();
      console.log(res, 'res');
      this.loading = false;
      if (res.status.code === 200) {
        this.list = res.data.display;
      }
    }
  }
};
</script>
<style scoped lang="less">
.home {
  padding-top: 46px;
  background: #f1f1f1;
  .title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    background: #fff;
    img {
      width: 250px;
      height: 80px;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 0px;
    }
    p {
      margin-top: 10px;
      margin-bottom: 0px;
      text-align: left;
    }
  }
  .list-wrap {
    background: #fff;
    margin-top: 10px;

    .list-title {
      font-size: 16px;
      height: 40px;
      border-bottom: 1px solid #f1f1f1;
      line-height: 40px;
      font-weight: bold;
      text-align: left;
      padding-left: 15px;
    }
    .skeleton {
      padding: 0 15px;
    }
    .list-item {
      margin: 0 15px;
      padding: 15px 0;

      border-bottom: 1px solid #f1f1f1;
    }
  }
  &.web {
    padding-top: 0;
    background: #fff;

    .con-wrap {
      width: 1000px;
      margin: 0 auto;
      .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        background: #fff;
        img {
          width: 400px;
          height: 150px;
        }
        h2 {
          width: 100%;
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 10px;
          margin-top: 0;
          margin-bottom: 10px;
          font-size: 28px;
          text-align: left;
        }
        h3 {
          width: 100%;
          margin-top: 10px;
          font-size: 20px;
          text-align: left;
        }
        p {
          margin-top: 0;
          margin-bottom: 0px;
          line-height: 30px;
          text-align: left;
          font-size: 15px;
        }
      }
      .list-wrap {
        .list-title {
          font-size: 20px;
          height: 50px;
          border-bottom: none;
          line-height: 50px;
          font-weight: bold;
          text-align: left;
          padding-left: 0;
        }
        .list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          .list-item {
            display: flex;
            flex-direction: column;
            margin-right: 40px;
            margin-left: 0;
            border: none;
          }
        }
      }
    }
  }
}
</style>
