import Vue from 'vue'
import { NavBar, Swipe, SwipeItem, SubmitBar, Toast, Icon, Popup, Form, Field, Button, ShareSheet } from 'vant'
Vue.use(NavBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(SubmitBar)
Vue.use(Icon)

Vue.use(Toast)
Vue.use(Popup)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(ShareSheet)
