import Vue from 'vue';
import { Button, Card, Carousel, PageHeader, Modal, Form, Input, Icon, Popover, Skeleton } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';

Vue.use(Button);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(PageHeader);
Vue.use(Modal);
Vue.use(Form);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Popover);
Vue.use(Skeleton);
