<template>
  <div id="app">
    <keep-alive :exclude="['Detail']">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {},
  props: {},
  data() {
    return {};
  },

  computed: {
    ...mapGetters(['ua'])
  },
  created() {},
  beforeMount() {
    const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    const recalc = () => {
      let ua = navigator.userAgent.toLowerCase();
      console.log(ua, 'ua');
      if (ua.includes('iphone') || ua.includes('android')) {
        ua = 'phone';
      } else {
        ua = 'web';
      }
      this.setUa(ua);
    };
    if (!document.addEventListener) return;
    recalc();
    window.addEventListener(resizeEvt, recalc, false);
    // document.addEventListener('DOMContentLoaded', recalc, false)
    window.recalc = recalc;
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapMutations({
      setUa: 'SET_UA'
    })
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.ellipsis {
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}
</style>
