/** @format */

import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import common from './modules/common'
import home from './modules/home'

import createLogger from 'vuex/dist/logger'
import { VUEX_DEFAULT_CONFIG } from '@/config'

Vue.use(Vuex)

export default new Vuex.Store({
  ...VUEX_DEFAULT_CONFIG,
  modules: {
    common,
    home,
  },
  getters,
  strict: VUEX_DEFAULT_CONFIG.strict,
  plugins: VUEX_DEFAULT_CONFIG.strict ? [createLogger()] : [],
})
