<template>
  <div class="card-item" v-if="ua === 'phone'" @click="goToDetail">
    <img :src="item.thumb_url" alt="" />
    <div class="card-con">
      <h3 class="ellipsis">{{ item.title }}</h3>
      <p class="ellipsis">{{ item.desc }}</p>
      <div class="tag-wrap" v-if="item.tags">
        <span>{{ item.viewNum }}</span> | <span>{{ item.payNum }}</span> |
        <span>{{ item.orderNum }}</span>
      </div>
      <h4>${{ item.price }}</h4>
    </div>
  </div>
  <div v-else class="card-item-web" @click="goToDetail">
    <a-card hoverable>
      <img slot="cover" alt="example" :src="item.thumb_url" />
      <h3 class="ellipsis">{{ item.title }}</h3>
      <p class="ellipsis">{{ item.desc }}</p>
      <div class="tag-wrap" v-if="item.tags">
        <span>{{ item.viewNum }}</span> | <span>{{ item.payNum }}</span> |
        <span>{{ item.orderNum }}</span>
      </div>
      <h4>${{ item.price }}</h4>
    </a-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  props: {
    item: {
      default: function () {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['ua'])
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    goToDetail() {
      this.$router.push({
        path: `/${this.item.name}`
      });
    }
  }
};
</script>

<style scoped lang="less">
.card-item {
  display: flex;
  align-items: center;
  img {
    width: 120px;
    height: 80px;
    margin-right: 10px;
  }
  .card-con {
    flex: 1;
    overflow: hidden;
  }
  h3 {
    text-align: left;
    font-size: 16px;
  }
  p {
    margin-top: 5px;
    text-align: left;
  }
  .tag-wrap {
    text-align: left;
    font-size: 12px;
    margin-top: 5px;
  }
  h4 {
    text-align: left;
    color: #fe7aa1;
    margin-top: 5px;
  }
}
.card-item-web {
  .ant-card {
    width: 300px;
    img {
      width: 100%;
      height: auto;
      max-height: 200px;
    }
    .card-con {
      flex: 1;
      overflow: hidden;
    }
    h3 {
      text-align: left;
      font-size: 16px;
    }
    p {
      margin-top: 5px;
      margin-bottom: 0;
      text-align: left;
    }
    .tag-wrap {
      margin-top: 5px;
      text-align: left;
      font-size: 12px;
    }
    h4 {
      text-align: left;
      color: #fe7aa1;
      margin-top: 5px;
    }
  }
}
</style>
